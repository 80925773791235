<template>
    <div class="wrapper position-relative d-flex align-items-center pt-body pb-5">
        <img :src="ImgBG" alt="Encuentra una mesa de regalo" class="w-100 h-100 img-background position-absolute top-0 bottom-0 start-0 end-0">
        <div class="row justify-content-center align-items-center mx-0 h-100 pt-5">
            <div class="col-lg-9">
                <div class="card p-0 h-100">
                    <div class="card-body p-0">
                        <div class="row align-items-stretch">
                            <div class="col-lg-4 rounded shadow overflow-hidden p-0" style="transform: scale(1.1);" v-if="!mobile">
                                <MesaCreateCarousel class="h-100" />
                            </div>
                            <div class="col-lg-8 py-3 px-5">
                                <div class="d-flex justify-content-between">
                                    <h1 class="text-center mb-2 text-primary">
                                        <small>Crea tu mesa</small>
                                    </h1>
                                    <Paises v-model="form.CODIGO_PAIS" required="required">
                                        <span v-if="errors.CODIGO_PAIS" class="text-danger">
                                            {{errors.CODIGO_PAIS[0]}}
                                        </span>
                                    </Paises>
                                </div>
                                <form type="POST" v-on:submit.prevent="addRegister" ref="mesaForm">
                                    <div class="row border-bottom">
                                        <div class="col-lg-12">
                                            <p class="text-muted mb-0">Tipo de celebración</p>
                                            <Celebraciones v-model="form.CODIGO_CELEBRACION" required="required" id="celebracion" @getName="getCelebracion2">
                                                <span v-if="errors.CODIGO_CELEBRACION" class="text-danger">
                                                    {{errors.CODIGO_CELEBRACION[0]}}
                                                </span>
                                            </Celebraciones>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="d-flex justify-content-between">
                                                <p class="text-muted mb-0">Tú información</p>
                                                <button type="button" class="btn btn-link text-info py-0 mb-2" v-on:click="infoMas=!infoMas" v-if="false">
                                                    <font-awesome-icon :icon="faArrowDown" class="me-2" v-if="infoMas" />
                                                    <font-awesome-icon :icon="faArrowRight" class="me-2" v-else />
                                                    <span v-if="infoMas">
                                                        Menos información
                                                    </span>
                                                    <span v-else>
                                                        Más información
                                                    </span>
                                                </button> 
                                            </div>
                                        </div>
                                        <div v-if="form.CODIGO_CELEBRACION != 4">
                                            <div class="col-lg-12 mb-2">
                                                <label for="nombre_pareja_1" class="form-label">Nombre completo</label>
                                                <input type="text" id="nombre_pareja_1" name="nombre_pareja_1" class="form-control" placeholder="*Ingresa tu nombre" v-model="form.NOMBRE_PAREJA_1" :maxlength="maxName" required>
                                                <span v-if="errors.NOMBRE_PAREJA_1" class="text-danger">
                                                    {{errors.NOMBRE_PAREJA_1[0]}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row" v-if="form.CODIGO_CELEBRACION == 4">
                                            <div class="col-lg-4 mb-2">
                                                <select name="tipo_pareja_1" id="tipo_pareja_1" class="form-select" v-model="form.TIPO_PAREJA_1" required>
                                                    <option value="" disabled selected>*Pareja</option>
                                                    <option value="novio">Novio</option>
                                                    <option value="novia">Novia</option>
                                                </select>
                                                <span v-if="errors.TIPO_PAREJA_1" class="text-danger">
                                                    {{errors.TIPO_PAREJA_1[0]}}
                                                </span>
                                            </div>
                                            <div :class="{'mb-2':true, 'col-lg-6':infoMas, 'col-lg-8':!infoMas}">
                                                <input type="text" id="nombre_pareja_1" name="nombre_pareja_1" class="form-control" placeholder="*Ingresa tu nombre" v-model="form.NOMBRE_PAREJA_1" :maxlength="maxName" required>
                                                <span v-if="errors.NOMBRE_PAREJA_1" class="text-danger">
                                                    {{errors.NOMBRE_PAREJA_1[0]}}
                                                </span>
                                            </div>

                                            <div class="col-lg-2 mb-2" v-if="infoMas">
                                                <input type="number" id="edad_pareja_1" name="edad_pareja_1" class="form-control" placeholder="Edad" v-model="form.EDAD_PAREJA_1" min="18" max="120" step="1">
                                                <span v-if="errors.EDAD_PAREJA_1" class="text-danger">
                                                    {{errors.EDAD_PAREJA_1[0]}}
                                                </span>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <p class="text-muted mb-0 mt-2">Información de tu pareja</p>
                                            </div>
                                            <div class="col-lg-4 mb-2">
                                                <select name="tipo_pareja_2" id="tipo_pareja_2" class="form-select" v-model="form.TIPO_PAREJA_2" required>
                                                    <option value="" disabled selected>*Pareja</option>
                                                    <option value="novio">Novio</option>
                                                    <option value="novia">Novia</option>
                                                </select>
                                                <span v-if="errors.TIPO_PAREJA_2" class="text-danger">
                                                    {{errors.TIPO_PAREJA_2[0]}}
                                                </span>
                                            </div>
                                            <div class="col-lg-8 mb-2">
                                                <input type="text" id="nombre_pareja_2" name="nombre_pareja_2" class="form-control" placeholder="*Ingresa tu nombre" v-model="form.NOMBRE_PAREJA_2" :maxlength="maxName" required>
                                                <span v-if="errors.NOMBRE_PAREJA_2" class="text-danger">
                                                    {{errors.NOMBRE_PAREJA_2[0]}}
                                                </span>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-4 mb-2" v-if="infoMas">
                                            <label for="correo_pareja_2" class="form-label">Correo electrónico</label>
                                            <input type="email" id="correo_pareja_2" name="correo_pareja_2" class="form-control" placeholder="Correo electrónico" v-model="form.CORREO_PAREJA_2" maxlength="150">
                                            <span v-if="errors.CORREO_PAREJA_2" class="text-danger">
                                                {{errors.CORREO_PAREJA_2[0]}}
                                            </span>
                                        </div>
                                        <div class="col-lg-4 mb-2" v-if="infoMas">
                                            <label for="telefono_pareja_2" class="form-label">Teléfono</label>
                                            <input type="tel" id="telefono_pareja_2" name="telefono_pareja_2" class="form-control" placeholder="Teléfono" v-model="form.TELEFONO_PAREJA_2" maxlength="150">
                                            <span v-if="errors.TELEFONO_PAREJA_2" class="text-danger">
                                                {{errors.TELEFONO_PAREJA_2[0]}}
                                            </span>
                                        </div>
                                        <div class="col-lg-4 mb-2" v-if="infoMas">
                                            <label for="edad_pareja_2" class="form-label">Edad</label>
                                            <input type="number" id="edad_pareja_2" name="edad_pareja_2" class="form-control" placeholder="Edad" v-model="form.EDAD_PAREJA_2" min="18" max="120" step="1">
                                            <span v-if="errors.EDAD_PAREJA_2" class="text-danger">
                                                {{errors.EDAD_PAREJA_2[0]}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col-lg-12 mt-2 mb-4">
                                            <label for="slug">*Personaliza tu URL</label>
                                            <div>
                                                <small :class="{'mb-0':true, 'text-muted':validateSlug,'text-muted':!validateSlug}" v-if="mobile">{{site}}/{{form.SLUG}}</small>
                                            </div>
                                            <div :class="{'input-group':!mobile}">
                                                <span class="input-group-text bg-primary text-white" id="slug-addon" v-if="!mobile">{{ site }}/</span>
                                                <input type="text" :class="{'form-control':true, 'border-danger':!validateSlug}" id="slug" aria-describedby="slug-addon" placeholder="Personaliza tu URL" v-model="form.SLUG" :maxlength="maxSlug" required @keydown.space.prevent>
                                            </div>
                                            <span v-if="errors.SLUG" class="text-danger">
                                                {{errors.SLUG[0]}}
                                            </span>
                                            <span v-if="!validateSlug" class="text-danger">
                                                La url no es válida
                                            </span>
                                        </div>
                                        
                                        <div class="col-lg-6 mb-3">
                                            <div class="d-flex justify-content-between">
                                                <label for="fecha_boda" class="form-label"><span v-if="!noFecha">*</span>Fecha</label>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="no-fecha" v-model="noFecha" @change="checkValidity()">
                                                    <label class="form-check-label form-label" for="no-fecha">
                                                        Aún no tenemos fecha
                                                    </label>
                                                </div>
                                            </div>

                                            <div v-if="!noFecha">
                                                <input type="date" class="form-control" v-model="form.FECHA_BODA" required>
                                                <span v-if="errors.FECHA_BODA" class="text-danger">
                                                    {{errors.FECHA_BODA[0]}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-12 mb-3">
                                            <label for="direccion" class="form-label">Dirección</label>
                                            <textarea name="direccion" id="direccion" class="form-control" placeholder="Dirección de envío" rows="1" maxlength="250" v-model="form.DIRECCION_ENVIO"></textarea>
                                            <span v-if="errors.DIRECCION_ENVIO" class="text-danger">
                                                {{errors.DIRECCION_ENVIO[0]}}
                                            </span>
                                        </div>
                                        
                                        <div class="col-lg-6 mb-3" v-if="infoMas">
                                            <label for="mensaje_invitados" class="form-label">Mensaje para invitados</label>
                                            <textarea name="mensaje_invitados" id="mensaje_invitados" class="form-control" placeholder="Mensaje para invitados" maxlength="250" v-model="form.MENSAJE_INVITADOS"></textarea>
                                            <span v-if="errors.MENSAJE_INVITADOS" class="text-danger">
                                                {{errors.MENSAJE_INVITADOS[0]}}
                                            </span>
                                        </div>

                                        <div class="col-lg-6 mb-3" v-if="infoMas">
                                            <label for="mensaje_agradecimiento" class="form-label">Mensaje de agradecimiento</label>
                                            <textarea name="mensaje_agradecimiento" id="mensaje_agradecimiento" class="form-control" placeholder="Mensaje de agradecimiento" maxlength="250" v-model="form.MENSAJE_AGRADECIMIENTO"></textarea>
                                            <span v-if="errors.MENSAJE_AGRADECIMIENTO" class="text-danger">
                                                {{errors.MENSAJE_AGRADECIMIENTO[0]}}
                                            </span>
                                        </div>

                                        <div class="col-lg-6 mb-3">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" id="publica" name="publica" v-model="form.PUBLICA">
                                                <label class="form-check-label" for="publica">Pública</label>
                                            </div>
                                            <span v-if="errors.PUBLICA" class="text-danger">
                                                {{errors.PUBLICA[0]}}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="text-center">
                                        <button type="submit" class="btn btn-primary btn-lg" :disabled="loading || !validateForm">
                                            <BtnLoading v-if="loadingMesa" />
                                            <span v-else>
                                                Crear mesa <font-awesome-icon :icon="faGifts" />
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowRight, faArrowDown, faGifts } from '@fortawesome/free-solid-svg-icons'
import ImgBG from '@/assets/img/backgrounds/Creatumesa.jpg'
import MesaCreateCarousel from '@/components/mesas/create/Carousel.vue'
import Paises from '@/components/catalogos/Paises.vue'
import Celebraciones from '@/components/catalogos/Celebraciones.vue'
import BtnLoading from '@/components/BtnLoading.vue'
export default {
    name: 'mesa-regalo-create',
    components: {
        MesaCreateCarousel,
        FontAwesomeIcon,
        BtnLoading,
        Paises,
        Celebraciones
    },
    data(){
        return {
            faArrowRight,
            faArrowDown,
            faGifts,
            ImgBG,
            validateSlug: true,
            infoMas: true,
            loadingMesa: false,
            validateForm: false,
            noFecha: false,
            maxSlug: 150,
            nombreCelebracion: '',
            form: {
                TIPO_PAREJA_1: 'novio',
                NOMBRE_PAREJA_1: '',
                CORREO_PAREJA_1: '',
                TELEFONO_PAREJA_1: '',
                EDAD_PAREJA_1: '',

                TIPO_PAREJA_2: 'novia',
                NOMBRE_PAREJA_2: '',
                CORREO_PAREJA_2: '',
                TELEFONO_PAREJA_2: '',
                EDAD_PAREJA_2: '',
                
                FECHA_BODA: '',
                DIRECCION_ENVIO: '',
                CODIGO_PAIS: '',
                CODIGO_CELEBRACION: '',
                SLUG: '',
                MENSAJE_INVITADOS: '',
                MENSAJE_AGRADECIMIENTO: '',
                PUBLICA: true,
                newSlug: '',
            }
        }
    },
    mounted(){
        this.defaultData()
    },
    methods: {
        defaultData(){
            if(this.user.CODIGO_BODA){
                this.$router.push({name: 'boda.index'})
            }
            if(this.user.SEXO == 'mujer'){
                this.form.TIPO_PAREJA_1 = 'novia'
                this.form.TIPO_PAREJA_2 = 'novio'
            } else {
                this.form.TIPO_PAREJA_1 = 'novio'
                this.form.TIPO_PAREJA_2 = 'novia'
            }

            if(!this.form.NOMBRE_PAREJA_1 && this.user.CODIGO_USUARIO){
                this.form.NOMBRE_PAREJA_1 = `${this.user.NOMBRES || ''} ${this.user.APELLIDOS || ''}`
            }
        },
        addRegister(){
            this.checkValidity()
            if (!this.validateForm) {
                return
            }
            if(this.noFecha){
                this.form.FECHA_BODA = null
            }
            this.loadingMesa = true
            this.$store.dispatch('addBoda', this.form).then((res) => {
                this.$toast.info(`La mesa de regalos ha sido generada`)
                this.$store.dispatch('getUsuario').then(x => {
                    this.$router.push({name: 'boda.index'})
                    return x
                })
                return res
            }).catch(err => {
                if(err.response){
                    if(typeof err.response.data === 'string'){
                        this.$toast.error(err.response.data)
                    }else{
                        this.$toast.error('Error al registrar mesa')
                    }
                }
            }).then(() => {
                this.loadingMesa = false
            })
        },
        validateUrl(value) {
            return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
        },
        checkValidity(){
            if (!this.$refs.mesaForm){
                this.validateForm = false
            }else{
                this.validateForm = this.$refs.mesaForm.checkValidity()
            }
        },

        getCelebracion2(celebracion){
            this.nombreCelebracion = celebracion.NOMBRE_CELEBRACION
            if(this.nombreCelebracion != 'Boda'){
                this.form.NOMBRE_PAREJA_2 = ''
            }

            this.updateNewSlug()
         },

         updateNewSlug(){
            let cel = this.nombreCelebracion.split(" ").join("-").toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            this.form.newSlug = cel
         },
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        errors() {
            return this.$store.getters.errors || {}
        },
        mobile() {
            return this.$store.getters.mobile
        },
        user() {
            return this.$store.getters.currentUser || {}
        },
        site(){
            let cel = this.nombreCelebracion.split(" ").join("-").toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            if (process.env.NODE_ENV !== 'production') {
                return `https://www.mesaregalo.com/${cel || 'celebracion'}`
            }

            return window.location.origin
        },
        nombrePareja1(){
            return this.form.NOMBRE_PAREJA_1
        },
        nombrePareja2(){
            return this.form.NOMBRE_PAREJA_2
        },
        slug(){
            return `${this.site}/${this.form.SLUG || ''}`
        },
        maxName(){
            return this.maxSlug/2
        },
        codigo(){
            return this.form.CODIGO_CELEBRACION || ''
        },

        celebraciones2() {
            var data = this.$store.getters.celebraciones || {}
            return data.data || []
        },

        celebracion2() {
            return this.celebraciones2.filter(x => x.CODIGO_CELEBRACION == this.codigo)[0] || {}
        },

        // getCelebracion2(celebracion){
        //     console.log(celebracion)
        //     return celebracion.NOMBRE_CELEBRACION 
            
        // },
    },
    watch: {
        nombrePareja1(val, oldVal){
            var nombre = (val.split(' ').join('_') || '').toLowerCase().substring(0, this.maxName)
            nombre = nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            var nombreOld = (oldVal.split(' ').join('_') || '').toLowerCase().substring(0, this.maxName)
            nombreOld = nombreOld.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            var nombrePareja = (this.form.NOMBRE_PAREJA_2.split(' ').join('_') || '').toLowerCase().substring(0, this.maxName-3)
            nombrePareja = nombrePareja.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

            if(this.form.CODIGO_CELEBRACION != 4){
                this.form.SLUG = `${nombre || ''}`
            }else{
                if(nombreOld || this.form.SLUG == ''){
                    this.form.SLUG = `${nombre || ''}-y-${nombrePareja || ''}`
                }
            }
            
        },
        nombrePareja2(val, oldVal){
            var nombre = (val.split(' ').join('_') || '').toLowerCase().substring(0, this.maxName-3)
            nombre = nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            var nombreOld = (oldVal.split(' ').join('_') || '').toLowerCase().substring(0, (this.maxName-3))
            nombreOld = nombreOld.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            var nombrePareja = (this.form.NOMBRE_PAREJA_1.split(' ').join('_') || '').toLowerCase().substring(0, this.maxName)
            nombrePareja = nombrePareja.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

            if(this.form.CODIGO_CELEBRACION == 4){
                if(nombreOld || this.form.SLUG == ''){
                    this.form.SLUG = `${nombrePareja || ''}-y-${nombre}`
                }
                
            }else{
                this.form.SLUG = `${nombre || ''}`
            }
        },
        slug(val){
            this.validateSlug = this.validateUrl(val)
        },
        'user'(val){
            this.defaultData()
            return val
        },
        'form':{
            handler(val){
                this.checkValidity()
                return val
            },
            deep: true
        }
    }
}
</script>