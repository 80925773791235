<template>
    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner h-100">
            <div class="carousel-item p-5 active bg-secondary h-100">
                <div class="d-flex flex-column justify-content-center text-center h-100">
                    <img :src="Bg1" class="img-fluid w-75 mx-auto" alt="Creación mesa - anillos">
                    <h5 class="mt-auto">Mesa de regalos</h5>
                    <p>Agrega tu mesa con los regalos que deseas.</p>
                </div>
            </div>
            <div class="carousel-item p-5 bg-primary text-white h-100">
                <div class="d-flex flex-column justify-content-center text-center h-100">
                    <img :src="Bg2" class="img-fluid w-75 mx-auto" alt="Creación mesa - flores">
                    <h5 class="mt-auto">Enlace propio</h5>
                    <p>Puedes personalizar tu enlace de evento.</p>
                </div>
            </div>
            <div class="carousel-item p-5 h-100" style="background-color: rgb(223, 201, 171);">
                <div class="d-flex flex-column justify-content-center text-center h-100">
                    <img :src="Bg3" class="img-fluid w-75 mx-auto" alt="Creación mesa - regalos">
                    <h5 class="mt-auto">Invitados</h5>
                    <p>Tus invitados podrán comprar los regalos, listos para envíarte.</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Bg1 from '@/assets/img/mesas/slide 1.jpg'
import Bg2 from '@/assets/img/mesas/slide 2.jpg'
import Bg3 from '@/assets/img/mesas/slide 3.jpg'
export default {
    data(){
        return {
            Bg1,
            Bg2,
            Bg3
        }
    }
}
</script>